import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0948d347"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detailsRow" }
const _hoisted_2 = { class: "detailsCaption" }
const _hoisted_3 = {
  key: 0,
  class: "detailsCol"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 2,
  class: "detailsCol"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 3,
  class: "detailsCol"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 4,
  class: "detailsCol"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MandatoryFieldFlag = _resolveComponent("MandatoryFieldFlag")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.caption ? _ctx.$t(_ctx.caption) : "") + " ", 1),
      (_ctx.required)
        ? (_openBlock(), _createBlock(_component_MandatoryFieldFlag, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    (
        !_ctx.detailsAreHtml && !_ctx.showDetailsAsBadge && !_ctx.detailsAreList && _ctx.details
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.details), 1))
      : _createCommentVNode("", true),
    (_ctx.detailsAreHtml && !_ctx.showDetailsAsBadge && !_ctx.detailsAreList)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "detailsCol",
          innerHTML: _ctx.details
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true),
    (_ctx.showDetailsAsBadge)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.showTooltip)
            ? (_openBlock(), _createBlock(_component_Tooltip, {
                key: 0,
                text: _ctx.tooltipText,
                bgColor: _ctx.tooltipColor,
                color: _ctx.tooltipTextColor
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Badge, {
                    rounded: true,
                    backgroundColor: _ctx.badgeBackgroundColor
                  }, {
                    default: _withCtx(() => [
                      (_ctx.showDetailsInBadge)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.details), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_7, " "))
                    ]),
                    _: 1
                  }, 8, ["backgroundColor"])
                ]),
                _: 1
              }, 8, ["text", "bgColor", "color"]))
            : (_openBlock(), _createBlock(_component_Badge, {
                key: 1,
                rounded: true,
                backgroundColor: _ctx.badgeBackgroundColor
              }, {
                default: _withCtx(() => [
                  (_ctx.showDetailsInBadge)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.details), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_9, " "))
                ]),
                _: 1
              }, 8, ["backgroundColor"]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.detailsAreList)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.detailsAreHtml)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item,
                    innerHTML: item
                  }, null, 8, _hoisted_12))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item) => {
                  return (_openBlock(), _createElementBlock("li", { key: item }, _toDisplayString(item), 1))
                }), 128))
              ]))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.details || !_ctx.details.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}