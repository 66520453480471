import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f3c00a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex flex-row align-items-center p-2" }
const _hoisted_3 = { class: "d-flex justify-content-start w-100" }
const _hoisted_4 = { style: {"padding":"0 10px"} }
const _hoisted_5 = { tabindex: "0" }
const _hoisted_6 = { class: "page-heading" }
const _hoisted_7 = { class: "d-flex justify-content-end w-100" }
const _hoisted_8 = { tabindex: "0" }
const _hoisted_9 = { style: {"padding":"0 10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.showPrevious)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              id: "previousBtn",
              class: "btn btn-link btn-block text-left",
              onfocus: "this.blur()",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('previous')))
            }, [
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_component_font_awesome_icon, { icon: "arrow-circle-left" })
              ]),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("pagination.previous")), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.showNext)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              id: "nextBtn",
              class: "btn btn-link btn-block text-right",
              onfocus: "this.blur()",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next')))
            }, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("pagination.next")), 1),
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_font_awesome_icon, { icon: "arrow-circle-right" })
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}