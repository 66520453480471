import { Constants } from "@/constants/constants";
import DateHelper from "@/helpers/date-helper";
import QuerystringHelper from "@/helpers/querystring-helper";
import {
  Course,
  CourseOccurrence,
  CourseOccurrenceBase,
  PagedList,
  QueryStringParameters,
} from "@/interfaces/api";
import { PersonCourse } from "@/interfaces/PersonCourse";
import BaseService from "@/services/base-service";

class CourseService extends BaseService {
  /**
   * Fetches all the courses for a person with a specific luEduPersonPrimaryId
   *
   * @param {string} luEduPersonPrimaryId - The luEduPersonPrimaryId of the person
   * @returns {Promise<Array<Course>>} A promise with all the courses
   */
  getCoursesByLuEduPersonPrimaryId(
    luEduPersonPrimaryId: string
  ): Promise<Array<Course>> {
    const endpointController = "Course";
    const endpointRoute = "luEduPersonPrimaryId";
    const paramName = "luEduPersonPrimaryId";
    const paramValue = luEduPersonPrimaryId;
    const querystring = `${paramName}=${paramValue}`;
    const getCoursesByLuEduPersonPrimaryId = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${querystring}`;
    return this.fetch<Array<Course>>(getCoursesByLuEduPersonPrimaryId);
  }

  // TODO: change name to getCourseInstances (includes "CourseOccurrence" in all object/property/variable/method names and comments)
  /**
   * Fetches all course occurrences according to the specified paging parameters
   *
   * @param {QueryStringParameters} parameters - A Promise with a paged list containing paging data and a list of
   *                                             all the course occurrences sorted according to the specified parameters
   */
  getCourseOccurrences(
    parameters: QueryStringParameters
  ): Promise<PagedList<CourseOccurrenceBase>> {
    const endpointController = "Course";
    const enpointRoute = "courseOccurrences";
    const queryString = this.generateQuerystring(parameters);
    const getCourseOccurrences = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}${queryString}`;
    return this.fetch<PagedList<CourseOccurrenceBase>>(getCourseOccurrences);
  }

  /**
   * Fetches the note taker with the specified id
   *
   * @param {number} id - The id of the note taker to fetch
   * @returns {Promise<NoteTaker | undefined>} A Promise with the note taker
   */
  getCourseOccurrenceById(id: number): Promise<CourseOccurrence | undefined> {
    const endpointController = "Course";
    const enpointRoute = "courseOccurrenceId";
    const paramName = "id";
    const paramValue = id;
    const getCourseOccurrenceById = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}?${paramName}=${paramValue}`;
    return this.fetch<CourseOccurrence>(getCourseOccurrenceById);
  }

  /**
   * Updates a course occurrence
   *
   * @param {CourseOccurrence} course - The course to update
   *
   * @returns {Promise<boolean>} A promise with a boolean value indicating whether or not the operation was successful
   */
  updateCourseOccurrence(course: CourseOccurrence): Promise<boolean> {
    const endpointController = "Course";
    const updateCourseOccurrence = `${this.baseApiUrl}api/${endpointController}`;
    return this.fetch<boolean>(updateCourseOccurrence, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(course),
    });
  }

  deleteNoteTakerFromCourseOccurrence(
    courseOccurrenceId: number,
    noteTakerId: number
  ): Promise<boolean> {
    const endpointController = "Course";
    const endpointRoute = "courseOccurrences/noteTaker";
    const parameterName = "courseOccurrenceId";
    const parameterArgument = courseOccurrenceId;
    const parameterName2 = "noteTakerId";
    const parameterArgument2 = noteTakerId;
    const queryString = `${parameterName}=${parameterArgument}&${parameterName2}=${parameterArgument2}`;
    const DeleteNoteTakerFromCourseOccurrence = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<boolean>(DeleteNoteTakerFromCourseOccurrence, {
      method: "DELETE",
    });
  }

  deleteStudentFromCourseOccurrence(
    courseOccurrenceId: number,
    studentId: number
  ): Promise<boolean> {
    const endpointController = "Course";
    const endpointRoute = "courseOccurrences/student";
    const paramName = "courseOccurrenceId";
    const paramValue = courseOccurrenceId;
    const paramName2 = "studentId";
    const paramValue2 = studentId;
    const queryString = `${paramName}=${paramValue}&${paramName2}=${paramValue2}`;
    const DeleteStudentFromCourseOccurrence = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<boolean>(DeleteStudentFromCourseOccurrence, {
      method: "DELETE",
    });
  }

  sendOfferToNoteTaker(
    courseOccurrenceId: number,
    noteTakerId: number
  ): Promise<boolean> {
    const endpointController = "Course";
    const endpointRoute = "courseOccurrences/offer";
    const paramName = "courseOccurrenceId";
    const paramValue = courseOccurrenceId;
    const paramName2 = "noteTakerId";
    const paramValue2 = noteTakerId;
    const queryString = `${paramName}=${paramValue}&${paramName2}=${paramValue2}`;
    const SendOfferToNoteTaker = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<boolean>(SendOfferToNoteTaker, { method: "POST" });
  }

  cancelOffer(offerId: number): Promise<boolean> {
    const endpointController = "Course";
    const endpointRoute = "courseOccurrences/offer";
    const paramName = "offerId";
    const paramValue = offerId;
    const CancelOffer = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${paramName}=${paramValue}`;
    return this.fetch<boolean>(CancelOffer, { method: "DELETE" });
  }

  /**
   * Returns a color based on course occurrence status
   *
   * @param {string} status The status we want a color for
   */
  courseOccurrenceStatusColor(status: string): string {
    switch (status) {
      case "Green":
        return "#3d7d40";
      case "Orange":
        return "#ef8943";
      case "Yellow":
        return "#f8dc7b";
      case "Red":
        return "#c83532";
      case "Grey":
        return "#909090";
      default:
        return "";
    }
  }

  /**
   * Returns a text based on course occurrence status
   *
   * @param {string} status The status we want text for
   */
  courseOccurrenceStatusText(status: string): string {
    return `course-occurrence.statusText.${status}`;
  }

  /**
   * Adds student to a course instance
   *
   * @param {number} studentId The id of the student
   * @param {string} courseUid The course Uid of where the student should be added
   * @param {string} facultySv The course's faculty name in Swedish
   * @param {string} occurrence A concatenation of the course code and term
   * @param {string} courseEndDate The course's end date
   *
   * @returns {Promise<boolean>} A promise with a boolean value indicating whether or not the operation was successful
   */
  addStudentToCourseInstance(
    studentId: number,
    course: Course
  ): Promise<boolean> {
    const endpointController = "Course";
    const endpointRoute = "courseOccurrences/student";
    const paramName = "studentId";
    const paramValue = studentId;
    const queryString = `${paramName}=${paramValue}`;
    const addStudentToCourseInstance = `${this.baseApiUrl}api/${endpointController}/${endpointRoute}?${queryString}`;
    return this.fetch<boolean>(addStudentToCourseInstance, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(course),
    });
  }

  /**
   * Returns a query string according to the specified filter parameters
   *
   * @param {QueryStringParameters} parameters - The filter/paging parameters to be added to the resource querystring
   * @returns {string} The generated query string
   */
  private generateQuerystring(parameters: QueryStringParameters): string {
    let queryString = "?";

    queryString += `CoordinatorId=${parameters.coordinatorId}&`;

    queryString +=
      QuerystringHelper.generateSearchAndPagingParameters(parameters);

    return queryString;
  }

  /**
   * Checks if the specified value is "unavailable" and if so, replaces it with the specified replacement.
   * This occurs when the backend could not find the course in Ladok
   *
   * Also checks if the value is a date and if so, formats it
   * This is in conjunction with the backend now using a DateTime type for dates instead of a string
   *
   * @param {string} value - The value to check
   *  @param {string} replacement - The replacement value
   *
   * @returns {string} The checked string
   */
  replaceUnvailableOrFormatDate(value: string, replacement: string): string {
    if (
      value === Constants.NotAvailable ||
      value.startsWith(Constants.MinDateValue)
    ) {
      value = replacement;
    } else if (this.startsWithIsoShortDate(value)) {
      value = DateHelper.formatDate(value);
    }

    return value;
  }

  /**
   * Checks if the specified string starts with an ISO short date
   *
   * @param {string} input - The string to check
   *
   * @returns {boolean} Whether the string starts with an ISO short date
   */
  startsWithIsoShortDate(input: string): boolean {
    const isoShortDatePattern = /^\d{4}-\d{2}-\d{2}/;
    return isoShortDatePattern.test(input);
  }

  /**
   * Sets the course title to be used as text for tooltip over link to course detail view
   * because just the text "unavailable" could be misunderstood to mean the linked view is unavailable
   */
  getCourseTitleTooltipText(title: string, unavailable: string): string {
    return `${title} ${unavailable.toLowerCase()}`;
  }

  /**
   * Checks if the granted support date is earlier than any of the selected course start dates
   *
   * @param {Date | string} grantedSupportDate The granted support date
   * @returns {Array<PersonCourse>} selectedCourses The selected courses
   *
   * @returns {Array<PersonCourse>} The courses that match the criteria
   */
  checkGrantedSupportDate(
    grantedSupportDate: Date | string,
    selectedCourses: Array<PersonCourse>
  ): Array<PersonCourse> {
    if (grantedSupportDate) {
      return selectedCourses.filter((course: PersonCourse) =>
        DateHelper.dateAisBeforeDateB(
          grantedSupportDate,
          course.courseStartDate
        )
      );
    }
    return new Array<PersonCourse>();
  }

  /**
   * Checks whether the course with the specified course Uid has an end date that has passed
   * Used for setting the disabled attribute on the course checkbox
   *
   * @param {string} courseUid The course Uid
   * @param {Array<Course>} courses The courses to search
   *
   * @returns {boolean} whether the course has an end date that has passed
   */
  isCompletedCourse(courseUid: string, courses: Array<Course>): boolean {
    if (!courses) {
      return false;
    }

    const completedCourse = courses.find(
      (course: Course) =>
        course.courseUid.toLowerCase() === courseUid.toLowerCase() &&
        DateHelper.dateAisBeforeDateB(course.courseEndDate, new Date())
    );

    return completedCourse != null;
  }
}

export default new CourseService();
