
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /*
     * Holds the id of the item and is populated from the route's id parameter when the page loads
     */
    title: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },

    showPrevious: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },

    showNext: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
  },
  emits: ["previous", "next"],
})
export default class DetailsHeader extends Vue {}
