import { SessionStorageKeys } from "@/constants/constants";
import { CoordinatorBase } from "@/interfaces/api";
import BaseService from "@/services/base-service";
import SessionStorageService from "@/services/sessionstorage-service";

class CoordinatorService extends BaseService {
  /**
   * Fetches the coordinators with the specified function id
   *
   * @param {number} functionId - The function id of the coordinators to fetch
   * @returns {Promise<CoordinatorBase[] | undefined>} A Promise with the coordinators
   */
  async getCoordinatorsByFunctionId(
    functionId: number
  ): Promise<CoordinatorBase[] | undefined> {
    const endpointController = "Coordinator";
    const parameterName = "functionId";
    const parameterArgument = functionId;
    const getCoordinatorByFunctionId = `${this.baseApiUrl}api/${endpointController}?${parameterName}=${parameterArgument}`;

    const coordinators = await this.fetch<CoordinatorBase[]>(
      getCoordinatorByFunctionId
    );

    if (coordinators) {
      SessionStorageService.setItem(
        SessionStorageKeys.Coordinators,
        coordinators
      );
    }

    return coordinators;
  }

  /**
   * Fetches the coordinators with the specified function id from session storage
   * if it exists, otherwise it fetches it from the API and stores it in session storage
   *
   * @param {number} functionId - The function id of the coordinators to fetch
   * @returns {Promise<CoordinatorBase[] | undefined>} A Promise with the coordinators
   */
  async getCachedCoordinatorsByFunctionId(
    functionId: number
  ): Promise<CoordinatorBase[] | undefined> {
    let coordinators = SessionStorageService.getItem<CoordinatorBase[]>(
      SessionStorageKeys.Coordinators
    );

    if (coordinators) {
      return coordinators;
    }

    coordinators = await this.getCoordinatorsByFunctionId(functionId);
    return coordinators;
  }
}

export default new CoordinatorService();
